/* ==========================================================================
   Dark cyan skin
   ========================================================================== */

/* Colors */
$background-color: #0f1b1b !default;
$text-color: #f7fffb !default;
$primary-color: #00e5ff !default;
$border-color: mix(#fff, $background-color, 20%) !default;
$code-background-color: mix(#000, $background-color, 15%) !default;
$code-background-color-dark: mix(#000, $background-color, 20%) !default;
$form-background-color: mix(#000, $background-color, 15%) !default;
$footer-background-color: mix(#000, $background-color, 30%) !default;
$link-color: mix($primary-color, $text-color, 75%) !default;
$link-color-hover: mix(#fff, $link-color, 25%) !default;
$link-color-visited: mix(#000, $link-color, 20%) !default;
$masthead-link-color: $text-color !default;
$masthead-link-color-hover: mix(#000, $text-color, 20%) !default;
$navicon-link-color-hover: mix(#000, $background-color, 30%) !default;

.author__urls.social-icons i,
.author__urls.social-icons .svg-inline--fa,
.page__footer-follow .social-icons i,
.page__footer-follow .social-icons .svg-inline--fa  {
  color: inherit;
}

.ais-search-box .ais-search-box--input {
  background-color: $form-background-color;
}

/* Wrap lines in code */
pre {
	white-space: pre-wrap;
}
